<template>
  <v-dialog v-model="dialog" persistent max-width="500px">
    <template v-slot:activator="{ on }">
      <!-- <v-btn text color="error" v-on="on">Reclamación general</v-btn> -->
      <v-tab v-on="on">Reclamación general</v-tab>
    </template>

    <v-form ref="reclamacion">
      <v-card>
        <v-card-title>
          <span>Nueva Reclamación</span>
        </v-card-title>

        <v-card-text>
          <v-autocomplete v-model="motivo" label="Motivo reclamación" required :items="listaMotivos"
            :rules="rules.notNull" append-icon="keyboard_arrow_down" item-value="id" item-text="label"></v-autocomplete>
          <v-autocomplete v-if="motivo === 2" v-model="corteID" :items="listaCortes" label="Corte" required placeholder="Seleccione un corte" item-text="fechas"
            item-value="CorteId" append-icon="keyboard_arrow_down"></v-autocomplete>
          <v-autocomplete v-if="motivo === 2" v-model="concepto" label="Componente/Incentivo reclamado" required
            multiple :rules="rules.notEmptyArray" :items="listaComponentes" item-value="IncentivoID" item-text="Nombre"
            append-icon="keyboard_arrow_down">
          </v-autocomplete>
          <v-textarea outline v-model="comentario" label="Comentario" required></v-textarea>
          <v-row row wrap ml-2>
            <v-col xs12>
              <v-text-field placeholder="Seleccionar anexos" @click="pickFile" v-model="fileNombre"
                prepend-icon="attach_file"></v-text-field>
              <input type="file" style="display: none" ref="excel" accept="*" @change="onFilePicked" multiple />
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-btn depressed color="primary" @click.stop="save" :disabled="bloque">Reclamar</v-btn>
          <v-btn text depressed color="primary" @click.stop="clear">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import apiReclamacion from "@/api/reclamaciones";
import apiComision from "@/api/comisiones";
import { mapActions } from "vuex";

export default {
  props: [
    "sistema",
  ],
  watch: {
    corteID: {
      handler: function () {
        
        this.getIncentivos();
        
      },
      immediate: true
    },

  },
  mounted() {
    this.getIncentivos();
  },

  data() {
    return {
      dialog: false,
      bloque: false,
      comentario: "",
      motivo: "",
      concepto: "",
      fileNombre: "",
      fileUrl: "",
      files: null,
      corteID: 0,
      listaMotivos: [
        {
          id: 1,
          label: "Sueldo variable incorrecto"
        },
        {
          id: 2,
          label: "Incentivo no pagado"
        },
        {
          id: -1,
          label: "Otros (especifique)"
        },
      ],
      listaComponentes: [],
      rules: {
        notNull: [(v) => !!v || "Este campo es requerido"],
        notEmptyArray: [(v) => v.length > 0 || "Este campo es requerido"],
      }
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    listaCortes() {
      return this.$store.getters.cortes;
    },
  },
  methods: {
    ...mapActions(["setError", "setErrorMessage", "setMessage"]),
    clear() {
      this.comentario = "";
      this.motivo = "";
      this.concepto = "";
      this.dialog = false;
      this.files = null;
    },
    save() {

      if (this.$refs.reclamacion.validate()) {
        this.bloque = true;

        if (!(this.user.asociado.canalAsociadoId || this.user.empleadoid)) {
          this.clear();
          return;
        }

        const motivo = this.listaMotivos.find(a => a.id === this.motivo)

        const Incentivos = [];

        if (this.concepto) {

          this.concepto.forEach(item => {
            const incentivo = this.listaComponentes.find(a => a.IncentivoID === item);

            if (incentivo) {
              Incentivos.push(incentivo)
            }
          })
        }

        let ReclamacionTipoCodigo = 'RECLAMACION_GENERAL'

        switch (motivo.id) {
          case -1:
            ReclamacionTipoCodigo = 'RECLAMACION_GENERAL'
            break;

          case 1:
            ReclamacionTipoCodigo = 'RECLAMACION_SALARIO'
            break;

          case 2:
            ReclamacionTipoCodigo = 'RECLAMACION_INCENTIVO'
            break;

          default:
            break;
        }


        let newRecord = {
          CanalAsociadoId: this.user.asociado.canalAsociadoId,
          EmpleadoId: this.user.empleadoid,
          CanalId: this.user.canalid,
          Sistema: this.sistema,
          Telefono: "",
          ProductCode: "",
          FechaActivacion: null,
          Serie: "",
          NumeroOs: "",
          ActivationId: 0,
          Concepto: this.concepto,
          Motivo: motivo.label,
          MotivoID: motivo.id,
          Comentario: this.comentario,
          Usuario: this.user.username,
          ReclamacionTipoCodigo,
          Incentivos,
          CorteID:this.corteID
        };
        let data = new FormData();
        data.append("reclamacion", JSON.stringify(newRecord));
        for (var key in this.files) {
          data.append("Archivos[]", this.files[key]);
        }

        apiReclamacion
          .addReclamacion(data)
          .then((response) => {
            newRecord.ReclamacionId = response.data.ReclamacionId;
            newRecord.Numero = response.data.Numero;
            let mensaje = {
              title: "Reclamación",
              body:
                "Se ha creado satisfactoriamente la reclamación número " +
                newRecord.Numero,
              type: "confirm",
            };
            this.setMessage(mensaje);
          })
          .catch((error) => {
            this.setError(error);
          })
          .finally(() => {
            this.bloque = false;
            this.clear();
          });
      }
    },
    pickFile() {
      this.$refs.excel.click();
    },
    onFilePicked(e) {
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.fileNombre = "";
        for (var i = 0, l = files.length; i < l; i++) {
          var item = files[i];
          this.fileNombre +=
            this.fileNombre.length === 0 ? item.name : ", " + item.name;
        }
        this.files = files;
        if (this.fileNombre.lastIndexOf(".") <= 0) {
          return;
        }
        const fr = new FileReader();
        fr.readAsDataURL(files[0]);
        fr.addEventListener("load", () => {
          this.fileUrl = fr.result;
          // this.file = files[0] // this is a file that can be sent to server...
        });
      } else {
        this.fileNombre = "";
        this.files = null;
        this.fileUrl = "";
      }
    },
    getIncentivos() {
      apiComision
        .getComponentesIncentivosByCanalCorte(this.user.canalid, this.user?.asociado?.asociadoComisionGroupID, this.corteID)
        .then((response) => {

          this.listaComponentes = response.data;

          let compOtros = {
            Nombre: "Otros",
            IncentivoID: 0
          };
          this.listaComponentes.push(compOtros);
        })
        .catch((error) => {
          this.setError(error);
        });
    }
  },
};
</script>

<style>

</style>
