<template>
  <v-app-bar app clipped-right flat fixed dark color="primary" height="60">
    <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
    <v-toolbar-title>
      <!-- <img width="100" height="50" src="@/assets/logo-indac-alt-filled.png" /> -->
      <strong>{{ breadcrumbs }}</strong>
    </v-toolbar-title>
    <v-spacer> </v-spacer>
    <v-menu offset-y dark>
      <template v-slot:activator="{ on }">
        <v-btn large text v-on="on">
          <v-icon left>account_circle</v-icon>
          {{ displayUser }}
          <v-icon right>more_vert</v-icon>
        </v-btn>
        <div style="width:15%;" v-show="selectedCorte && empleadoid">
          <br />
          <v-select v-model="selectedCorte" :items="corteOptions" placeholder="Seleccione un corte" item-text="fechas"
            item-value="CorteId" append-icon="keyboard_arrow_down" label="Corte"></v-select>
        </div>
      </template>

      <v-list nav>
        <template v-for="item in getSubItem(currentUser.accesos, 'type', 5)">
          <v-list-item :key="item.key" @click="go(item)">
            <v-list-item-title>
              <v-icon left v-text="item.icon"></v-icon>
              {{ item.title }}
            </v-list-item-title>
          </v-list-item>
        </template>

        <v-list-item to="/ayuda">
          <v-list-item-title>
            <v-icon left>mdi-help-circle</v-icon>Ayuda
          </v-list-item-title>
        </v-list-item>

        <v-list-item to="/logout">
          <v-list-item-title>
            <v-icon left>mdi-logout-variant</v-icon>Cerrar sesión
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <template v-if="$route.name != 'home' && (options && options.length > 0)" v-slot:extension>
      <v-tabs :key="selectedCorte" align-with-title>
        <v-tab v-for="(item, index) in options" :key="index" :to="item.path">{{
            item.title
        }}</v-tab>



        <reclamacion-general-modal
          v-if="$route.name === 'reclamacion-masiva-empleado' || $route.name === 'reclamacion-consulta-empleado' || $route.name === 'reclamacion-empleado'"
          sistema="Interno">
        </reclamacion-general-modal>

      </v-tabs>
    </template>
  </v-app-bar>
</template>
<script>
import apiEmpleado from "@/api/empleados";
import moment from 'moment'
import ReclamacionGeneralModal from "@/components/reclamacion/reclamacion_general_creacion";
export default {
  name: "app-bar",
  components: {
    ReclamacionGeneralModal
  },
  data: () => ({
    corteOptions: [],
    selectedCorte: null,
    empleadoid: null,
    user: null,
  }),
  computed: {
    currentUser() {
      return this.$store.getters.user;
    },
    accesos() {
      return this.$store.getters.user.accesos.length > 0
        ? this.$store.getters.user.accesos.filter((c) => c.canal == this.canal)
        : [];
    },
    options() {
      if (this.$store.getters.parentKey == null) return null;
      return this.$store.getters.user.accesos.filter(
        (c) => c.parentKey == this.$store.getters.parentKey
      );
    },
    breadcrumbs: {
      get() {
        this.getCortesEmpleado();
        return this.$store.getters.breadcrumbs;
      },
    },
    drawer: {
      get() {
        return this.$store.getters.leftdrawer;
      },
      set(value) {
        this.$store.commit("setLeftDrawer", value);
      },
    },
    displayUser() {
      if (this.currentUser) {
        this.getCortesEmpleado();
        return this.currentUser.DisplayUser;
      } else {
        return "";
      }
    },
    canal() {
      return this.$store.getters.canal;
    },
  },
  methods: {
    getSubItem(list, property, value) {
      return list === undefined
        ? []
        : list.filter((c) => c[property] === value);
    },
    go(item) {
      this.$store.commit("setParentKey", item.key);
      this.$router.push({ path: item.path });
    },
    getCortesEmpleado() {

      this.empleadoid = this.$store.getters.user.empleadoid;
      if (this.currentUser && this.empleadoid) {

        apiEmpleado.getCortes(this.empleadoid).then((response) => {
          this.corteOptions = response.data;

          response.data.forEach(item => {
            item.fechas = item.Nombre.replace('WEE', ' - ') + ' (' + moment(item.StartDate).format('DD/MM/YYYY') + ' - ' + moment(item.EndDate).format('DD/MM/YYYY') + ')'
          })

        });

        if (this.$store.getters.user.corte) {
          this.selectedCorte = this.$store.getters.user.corte.CorteId;
        }

      }
    },
    switchCorte() {
      if (this.currentUser && this.selectedCorte) {
        this.user = this.$store.getters.user
        this.user.corteId = this.selectedCorte;
        let _corte = this.$store.getters.cortes.find(
          (c) => c.CorteId === this.selectedCorte);

        if (!_corte) {
          _corte = this.corteOptions.find(
            (c) => c.CorteId === this.selectedCorte);
        }
        if(_corte)
        {
          this.user.corte = _corte;
        }
     
        this.$store.state.updateCorte = this.user.corteId;
        this.$store.dispatch("setUser", this.user);
      }
    }
  },
  watch: {
    selectedCorte: function () {
      this.switchCorte();
    }
  },
  props: {
    source: String,
  },
};
</script>